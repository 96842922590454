@import url(https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  padding: 0;
  margin: 0;
}

body {
  background-color: #1b1d11;
  color: #d7e1f5;
  font-family: "Roboto";
  -ms-scrollbar-base-color: #3689c9;
}

.navbar {
  display: grid;
  grid-template-columns: auto auto auto;
  height: 4rem;
  width: 100%;
}
.navbar .brand {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  color: #d7e1f5;
  text-decoration: none;
  font-size: 2rem;
  margin-left: 10rem;
  align-items: center;
  font-weight: bold;
}
.navbar .center-items {
  display: grid;
  grid-template-columns: auto;
}
.navbar .center-items a {
  text-decoration: none;
  color: #d7e1f5;
  font-size: 1.25rem;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  transition: 0.5s;
}
.navbar .center-items a:hover {
  color: #3689c9;
}
.navbar .login {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  -webkit-align-items: center;
          align-items: center;
  font-size: 1.5rem;
  margin-right: 10rem;
  color: #d7e1f5;
  text-decoration: none;
  transition: 0.5s;
}
.navbar .login:hover {
  color: #3689c9;
}

.welcome {
  width: 100%;
  height: calc( 100vh - 4rem );
  overflow: auto;
}
.welcome::after {
  content: "";
  clear: both;
  display: table;
}
.welcome img {
  display: -webkit-flex;
  display: flex;
  float: right;
  margin-right: 12rem;
  margin-top: 8rem;
}
.welcome h1 {
  font-size: 2rem;
  float: left;
  margin-top: calc( calc( 100vh - 4rem - 2rem ) / 2 );
  margin-left: 15rem;
}

.products {
  width: 50%;
  margin: 2rem auto;
}
.products .top .item:nth-child(1) {
  padding: 2rem;
  height: 5rem;
  width: 25%;
  border-right: 2px solid white;
}

/*# sourceMappingURL=App.css.map */

